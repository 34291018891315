import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.array.map.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import draggable from "vuedraggable";
import CommonMixin from "@/core/plugins/common-mixin";
import ListingMixin from "@/core/plugins/listing-mixin";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import TableActivity from "@/view/pages/partials/Table-Activity.vue";
import ListingTemplate from "@/view/pages/partials/Listing-Template.vue";
import ListingTable from "@/view/pages/partials/Listing-Table.vue";
import ListingHeader from "@/view/pages/partials/Listing-Header.vue";
import ListingFooter from "@/view/pages/partials/Listing-Footer.vue";
import ListingSearchCriteria from "@/view/pages/partials/Listing-Search-Criteria.vue";
import KTLayoutQuickUser from "@/assets/js/layout/extended/quick-user.js";
import KTOffcanvas from "@/assets/js/components/offcanvas.js";
import Priority from "@/view/pages/partials/Priority";
import CustomStatus from "@/view/pages/partials/CustomStatus";
export default {
  mixins: [CommonMixin, ListingMixin],
  name: "bug-list",
  data: function data() {
    return {
      pageModule: "bug-listing",
      routeAPI: "bug",
      routeName: "bug.report",
      routeDetailName: "detail.bug.report",
      status: "all",
      statusList: []
    };
  },
  components: {
    draggable: draggable,
    Priority: Priority,
    CustomStatus: CustomStatus,
    TableActivity: TableActivity,
    ListingTemplate: ListingTemplate,
    ListingFooter: ListingFooter,
    ListingTable: ListingTable,
    ListingHeader: ListingHeader,
    ListingSearchCriteria: ListingSearchCriteria
  },
  mounted: function mounted() {
    new KTOffcanvas(KTLayoutQuickUser.getElement()).hide();
    this.$store.dispatch(SET_BREADCRUMB, [{
      title: "Bug Report"
    }]);
  },
  beforeMount: function beforeMount() {
    var _this = this;

    _this.lodash.assign(_this.queryParams, _this.$route.query);

    _this.defaultColDefs = [{
      headerName: "Title",
      field: "title",
      sort: null,
      image: true,
      visible: true,
      fixed: false,
      sortable: false,
      order: 1
    }, {
      headerName: "Status",
      field: "status",
      sort: null,
      image: true,
      visible: true,
      fixed: false,
      sortable: false,
      order: 2
    }, {
      headerName: "Priority",
      field: "priority",
      sort: null,
      image: true,
      visible: true,
      fixed: false,
      sortable: false,
      order: 3
    }, {
      headerName: "Created Time",
      child: "created_at",
      field: "added_at",
      sort: null,
      visible: true,
      fixed: false,
      sortable: false,
      order: 4
    }, {
      headerName: "Last Modified Time",
      child: "modified_at",
      field: "updated_at",
      sort: null,
      visible: false,
      fixed: false,
      sortable: false,
      order: 5
    }];

    var defaultColDefs = _this.lodash.filter(_this.defaultColDefs, {
      visible: true
    });

    _this.defaultColShow = defaultColDefs.map(function (col) {
      return col.field;
    });
    _this.status = _this.$route.query.status || _this.status;

    _this.applyColState();

    _this.paginationPageSize = window.localStorage.getItem(_this.pageModule) || 10;
  }
};